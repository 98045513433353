:root{
  --MyYellow: hsl(46, 100%, 91%);
}
.MainHeader{
  position: relative;
}
.MainHeader::after{
  width: 90%;
  height: 2px;
  content: '';
  background-color: pink;
  position: absolute;
  left: 5%;
}
.Header{
  height: 75px;
  max-width: 500px;
  margin: 0 auto;
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

}
.Tab{
  color: black;
  font-size: 22px;
  text-align: center;
  align-content: center;
  text-decoration: none;
}
.Tab:hover{
  color: hotpink;
}
.Grid{
  padding-top: 50px;
  width: 80%;
  margin: 0 auto;
}
.GridSection{
  overflow: hidden;
  border-radius: 20px;
  width: 90%;
  transition: ease-in-out .25s;
  border: 2px solid pink;
}
.GridSection:hover{
  box-shadow: 3px 3px 10px hotpink;
}
.GridSection > img {
  width: 100%;
}
.GridSectionCaption{
  padding: 30px 0px;
}
article{
  text-align: center;
}
.ContentLink{
  text-decoration: none;
  color: black;
}
.SectionCard{
  margin: 25px;
  box-shadow: 0px 0px 10px black;
}